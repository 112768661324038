import React from "react";
import Navbar from "../sections/Navbar/Navbar";
// @ts-ignore
import Seo from "../utils/seo";
import IndividualCoachingHero from "../sections/IndividualCoachingHero";
import Benefits from "../sections/Benefits";
import Example from "../sections/Example";
import ProgramHelp from "../sections/ProgramHelp";
import Faq from "../sections/Faq";
import Footer from "../sections/Footer";
import InternalCoachingHero from "../sections/InternalCoachingHero.";
import CaseStudies from "../sections/CaseStudies";
// @ts-ignore
import Layout from "../layouts";
import Leaders from "../sections/Leaders";
const InternalCoaching = () => {
  return (
    <>
      <Seo title="Transformative Coaching for Internal Growth by Coachello" description="Internal Coaching by Coachello: build a thriving, empowered team with personalized growth strategies for lasting success."
        // image={seoImg}
      />
      <Layout>
      <InternalCoachingHero />
      <CaseStudies />
      <Leaders />
      <Faq />
      </Layout>
    </>
  );
};

export default InternalCoaching;
